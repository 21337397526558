import "./ChangeEmailModalWindow.css";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import ModalWindow from "../ModalWindow/ModalWindow";
import TextInput, {TextInputSize} from "../TextInput/TextInput";
import React from "react";


interface ChangeEmailModalWindowProps {
  onClose: () => void;
  onAccept: (email: string) => void;
}

const VerifyCodeModalWindow = (
  props: ChangeEmailModalWindowProps
) => {
  const [email, setEmail] = React.useState("");

  return (
    <ModalWindow onClickOutside={props.onClose} contentClassName={"change-email-modal-content"}>
      <div className={"change-email-modal-window"}>
        <div className={"change-email-modal-window__body"}>
          <div className={"change-email-modal-window__title"}>
          </div>
          <div className={"change-email-modal-window__input"}>
            <TextInput
              id={"change-email"}
              onChange={(v) => setEmail(v)}
              value={email}
              label={"Новая электронная почта"}
              editable
              required
              size={TextInputSize.Middle}
            />
          </div>
        </div>
        <div className={"change-email-modal-window__footer"}>
          <Button
            onClick={() => props.onAccept(email)}
            size={ButtonSize.Big}
            style={ButtonStyle.Primary}
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </ModalWindow>
  );
};

export default VerifyCodeModalWindow;
