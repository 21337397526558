import React, {PropsWithChildren} from 'react';
import {ClaimType, ClaimValue} from "../../models/auth";
import {useStores} from "../../store";


interface ClaimAuthorizeProps {
    claimType: ClaimType,
    claimValues: ClaimValue | ClaimValue[]
}

const ClaimAuthorize = ({claimType, claimValues, children}: PropsWithChildren<ClaimAuthorizeProps>) => {
    const {store} = useStores();
    let claimValuesArr = [];
    if (Array.isArray(claimValues)) {
        claimValuesArr = claimValues;
    } else {
        claimValuesArr = [claimValues];
    }

    return (
      <>
          {store.auth.authorize(claimType, claimValuesArr) && children}
      </>
    );
};

export default ClaimAuthorize;
