import React from "react";
import "./VerifyCodeModalWindow.css";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import ModalWindow from "../ModalWindow/ModalWindow";
import {RequestState} from "../../types/RequestState";
import TextInput, {TextInputSize} from "../TextInput/TextInput";


interface VerifyCodeModalWindowProps {
  onClose: () => void;
  onAccept: (verifyCode: string) => void;
  requestState: RequestState;
  errorMessage?: string;
  onRetrySendCode: () => void;
  initRenewAt: number;
}

const VerifyCodeModalWindow = (
  props: VerifyCodeModalWindowProps
) => {
  const [code, setCode] = React.useState("");
  const [renewAt, setRenewAt] = React.useState(0);


  React.useEffect(() => {
    setRenewAt(120);
    const interval = setInterval(() => {
      setRenewAt((prev) => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [props.initRenewAt]);

  return (
    <ModalWindow onClickOutside={props.onClose} contentClassName={"verify-code-modal-content"}>
      <div className={"verify-code-modal-window"}>
        <div className={"verify-code-modal-window__body"}>
          <div className={"verify-code-modal-window__title"}>
          </div>
          <div className={"verify-code-modal-window__input"}>
            <TextInput
              id={"verify-code"}
              onChange={(v) => setCode(v)}
              value={code}
              label={"Код подтверждения"}
              editable={true}
              required
              maxLength={4}
              size={TextInputSize.Middle}
            />
            {props.errorMessage &&
              <div className="verify-code-message verify-code-message--error">{props.errorMessage}</div>}
          </div>
          <div className={"verify-code-modal-window__renew"}>
            {renewAt > 0 && <span>Повторная отправка кода через {renewAt} секунд</span>}
            {renewAt == 0 && <div className={"verify-code-modal-window__renew-link"} onClick={props.onRetrySendCode}>Отправить повторно</div>}
          </div>
        </div>
        <div className={"verify-code-modal-window__footer"}>
          <Button
            onClick={() => props.onAccept(code)}
            size={ButtonSize.Big}
            style={ButtonStyle.Primary}
            pending={props.requestState == RequestState.Pending}
          >
            Отправить
          </Button>
        </div>
      </div>
    </ModalWindow>
  );
};

export default VerifyCodeModalWindow;
