import React, {ReactNode, SyntheticEvent, useEffect, useState} from "react";
import "./ModalWindow.css";
import {createPortal} from "react-dom";
import {CSSTransition} from "react-transition-group";
import classNames from "classnames";

interface ModalWindowProps {
  contentClassName?: string;
  children: ReactNode;
  onClickOutside: () => void;
}

const ModalWindow = (props: ModalWindowProps) => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setIsShow(true);
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const clickOutside = (event: SyntheticEvent) => {
    // event.preventDefault();
    // event.stopPropagation();
    if (event.target === event.currentTarget) {
      setIsShow(false);
    }
  };

  return createPortal(
    <div className={"modal-window"} onClick={clickOutside}>
      <CSSTransition
        onExited={props.onClickOutside}
        in={isShow}
        classNames="modal-window"
        timeout={500}
        unmountOnExit
      >
        <div className={classNames(
          "modal-window__content", 
          props.contentClassName
        )}>
          {props.children}
        </div>
      </CSSTransition>
    </div>,
    document.body
  );
};

export default ModalWindow;
