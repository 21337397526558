import React, {useState} from "react";
import "./SupportPage.css";
import Form from "../components/Form/Form";
import FormRow from "../components/Form/FormRow";
import TextInput from "../components/TextInput/TextInput";
import ID from "../helpers/id";
import Title from "../components/Title/Title";
import Button, {ButtonSize, ButtonStyle} from "../components/Button/Button";
import {sendMessage} from "../services/MessagesService";
import Accordion from "../components/Accordion/Accordion";
import AccordionTitle from "../components/AccordionTitle/AccordionTitle";
import AccordionContent from "../components/AccordionContent/AccordionContent";
import clientProfile from "../client-profile";

const SupportPage = () => {

    const [message, setMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [pendingSentMessage, setPendingSentMessage] = useState(false);

    let send = () => {
        if (!message) {
            return;
        }
        setPendingSentMessage(true);
        sendMessage({message})
            .finally(() => {
                setMessage('');
                setMessageSent(true);
                setPendingSentMessage(false);
            });
    }
    return (
        <div className={"support-page"}>
            <div className={"support-page__title"}>
                <Title as={"h1"}>Поддержка</Title>
            </div>
            <div className={"support-page__body"}>
                <div className={"support-page__form"}>
                    <div className={"support-page__form-title"}>
                        <Title as={"h3"}>Написать в службу поддержки</Title>
                    </div>

                    <Form>
                        <FormRow>
                            <TextInput
                                label={"Ваше сообщение"}
                                id={ID()}
                                onChange={(value) => setMessage(value)}
                                value={message}
                                editable
                            />
                        </FormRow>
                        <FormRow>
                            <Button
                                onClick={send}
                                size={ButtonSize.Big}
                                style={ButtonStyle.Primary}
                                pending={pendingSentMessage}
                            >
                                Отправить
                            </Button>
                        </FormRow>
                        {messageSent && <FormRow>
                            Сообщение отправлено
                        </FormRow>}
                    </Form>
                </div>
                <div className={"support-page__contacts"}>
                    <div className={"support-page__contacts-title"}>
                        <Title as={"h3"}>Контакты службы поддержки</Title>
                    </div>
                    <div className={"support-page__contact-item"}>
                        <div className={"support-page__contact-title"}>Телефон</div>
                        <div className={"support-page__contact-value"}>
                            +7 (499) 110-00-25
                        </div>
                        <div className={"support-page__contact-text"}>
                            Ежедневно по будням с 10:00 до 18:00 по московскому времени
                        </div>
                    </div>
                    <div className={"support-page__contact-item"}>
                        <div className={"support-page__contact-title"}>Электронная почта</div>
                        <div className={"support-page__contact-value"}>
                            support@vektor-adv.ru
                        </div>

                    </div>
                </div>
            </div>
            {clientProfile.faq && <div className={"support-page__faq"}>
                <Title as={"h3"}>Часто задаваемые вопросы</Title>
                <br/>
                <Accordion>
                    <AccordionTitle>Как поменять почту?</AccordionTitle>
                    <AccordionContent>
                        В разделе "Настройки профиля" нажмите на кнопку "Поменять" напротив поля с почтой. В открывшемся окне укажите новую почту и нажмите "Подтвердить". На телефон, который указан в профиле, поступит смс с кодом подтверждения - введите его в соответствующее поле.
                    </AccordionContent>
                </Accordion>

                <Accordion>
                    <AccordionTitle>Как подтвердить почту?</AccordionTitle>
                    <AccordionContent>
                        В разделе «Настройки профиля» отправьте себе повторное письмо для подтверждения (первый раз оно
                        приходит при регистрации, но имеет короткий срок действия). Письмо должно поступить на почту,
                        которую Вы указали при регистрации. Не забывайте проверять папку спам.
                    </AccordionContent>
                </Accordion>

                <Accordion>
                    <AccordionTitle>Не приходит письмо для подтверждения</AccordionTitle>
                    <AccordionContent>
                        Проверьте папку спама и рассылок. Если Вы указали корпоративную почту - ее необходимо заменить
                        на личную, так как автоматические письма блокируются внутренней системой. Если Ваш вопрос не
                        подходит под эти два пункта, то обратитесь в Службу поддержки.
                    </AccordionContent>
                </Accordion>
                <Accordion>
                    <AccordionTitle>Как получить сертификаты?</AccordionTitle>
                    <AccordionContent>
                        <ul>
                            <li>Подтвердите почту в личном кабинете и заполните профиль, если это необходимо</li>
                            <li>В разделе «Активировать код» введите кодовое слово (если код вводили при регистрации, то
                                повторно данный код вводить не нужно) - на балансе в правом верхнем углу должны
                                появиться баллы
                            </li>
                            <li>В разделе «Каталог» нажмите на карточку магазина, который Вам нужен</li>
                            <li>Выберете номинал, добавьте его в Корзину</li>
                            <li>В Корзине заказ необходимо подтвердить</li>
                        </ul>
                    </AccordionContent>
                </Accordion>
                <Accordion>
                    <AccordionTitle>Не могу активировать код</AccordionTitle>
                    <AccordionContent>
                        Для начала убедитесь, что Вы находитесь на сайте своей программы: проверьте логотип в левом
                        верхнем углу (еще он отображается на странице авторизации).

                        Если опознавательных знаков нет или Вы находитесь на нужном сайте - проверьте корректность ввода
                        символов:
                        <ul>
                            <li>в коде должно быть 10 символов</li>
                            <li>все буквы в коде маленькие</li>
                        </ul>
                        Если код все же не удается активировать - обратитесь в Службу поддержки.
                    </AccordionContent>
                </Accordion>
                <Accordion>
                    <AccordionTitle>Когда поступит мой заказ?</AccordionTitle>
                    <AccordionContent>
                        Сертификаты приходят в течение дня, если в момент заказа имеются на складе. Если на складе
                        сертификатов нет, то срок доставки составляет 3-5 рабочих дней, не считая дня заказа. Наличие на
                        складе отображается при выборе номинала в карточке магазина.
                    </AccordionContent>
                </Accordion>
                <Accordion>
                    <AccordionTitle>Пришел не весь заказ</AccordionTitle>
                    <AccordionContent>
                        Проверьте статус заказа в разделе «События». Если статус «доставлен», то Вы можете
                        продублировать заказ повторно на почту. Не забывайте проверять папку спам. Если статус «частично
                        доставлен», а срок доставки уже прошел - обратитесь в Службу поддержки.
                    </AccordionContent>
                </Accordion>
                <Accordion>
                    <AccordionTitle>Как повторно отправить себе заказ?</AccordionTitle>
                    <AccordionContent>
                        В разделе «События» справа от заказа есть кнопка «Отправить на почту». Не забывайте проверять
                        папку спам.
                    </AccordionContent>
                </Accordion>
                <Accordion>
                    <AccordionTitle>Как поменять телефон в личном кабинете?</AccordionTitle>
                    <AccordionContent>
                        Для замены данных в личном кабинете в разделе «Поддержка» напишите «хочу поменять номер телефона
                        на *актуальный номер*». Не забудьте указать актуальный номер, иначе запрос придется отправлять
                        повторно.
                    </AccordionContent>
                </Accordion>
            </div>
            }
        </div>
    );
};

export default SupportPage;
