import {RootStore} from "./RootStore";
import {makeAutoObservable} from "mobx";
import {AddIncomeTaxDataRequestModel} from "../types/AddIncomeTaxDataRequestModel";
import {
    acceptOffer,
    addEmail,
    addIncomeTaxData, changeEmail, changeEmailWithVerifyCode,
    confirmEmail,
    getBalance,
    getEvent,
    getProfile,
    resendEmailConfirmLink, updateCardNumber, updateDealer,
} from "../services/ParticipantService";
import {RequestState} from "../types/RequestState";
import {ProfileModel} from "../types/ProfileModel";
import {EventModel} from "../types/EventModel";
import {ApiResult} from "../types/CheckVerifyCodeResult";

export class ParticipantStore {
    private _rootStore: RootStore;

    balance: number = 0;
    profile: ProfileModel | null;
    events: EventModel[] = [];

    addIncomeTaxDataRequestState = RequestState.Init;
    confirmEmailRequestState = RequestState.Init;
    resendEmailConfirmLinkState = RequestState.Init;
    getBalanceRequestState = RequestState.Init;
    getProfileRequestState = RequestState.Init;
    getEventRequestState = RequestState.Init;
    updateCardNumberState = RequestState.Init;
    updateDealerState = RequestState.Init;
    acceptRuleState = RequestState.Init;
    updateEmail = RequestState.Init;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this._rootStore = rootStore;
        this.profile = null;
    }

    decreaseBalance(sum: number) {
        this.balance = this.balance - sum;
    }

    getEvent() {
        this.getEventRequestState = RequestState.Pending;
        getEvent()
            .then((events) => {
                this.getEventRequestState = RequestState.Done;
                this.events = events;
            })
            .catch((e) => {
                this.getEventRequestState = RequestState.Error;
                console.log(e)
            });
    }

    getBalance() {
        this.getBalanceRequestState = RequestState.Pending;
        getBalance()
            .then((result) => {
                this.getBalanceRequestState = RequestState.Done;
                this.balance = result;
            })
            .catch((error) => {
                this.getBalanceRequestState = RequestState.Error;
            });
    }

    getProfile() {
        this.getProfileRequestState = RequestState.Pending;
        return getProfile()
            .then((result) => {
                this.getProfileRequestState = RequestState.Done;
                this.profile = result;
            })
            .catch((error) => {
                this.getProfileRequestState = RequestState.Error;
            });
    }

    updateDealer(dealer: string) {
        this.updateDealerState = RequestState.Pending;
        updateDealer(dealer)
            .then(() => {
                this.updateDealerState = RequestState.Done;
                this.getProfile();
            })
            .catch((error) => {
                this.updateDealerState = RequestState.Error;
            });
    }

    acceptOffer() {
        return acceptOffer()
            .then(() => {
                this.acceptRuleState = RequestState.Done;
                this.getProfile();
            })
            .catch((error) => {
                this.acceptRuleState = RequestState.Error;
                throw error;
            });
    }

    updateCardNumber(cardNumber: string) {
        this.updateCardNumberState = RequestState.Pending;
        return updateCardNumber(cardNumber)
            .then(() => {
                this.updateCardNumberState = RequestState.Done;
                this.getProfile();
            })
            .catch((error) => {
                this.updateCardNumberState = RequestState.Error;
                throw error;
            });
    }

    addEmail(email: string) {
        return addEmail(email)
            .then(() => {
                this.getProfile();
            })
            .catch((error) => {
                throw error;
            });
    }


    addIncomeTaxData = (request: AddIncomeTaxDataRequestModel) => {
        this.addIncomeTaxDataRequestState = RequestState.Pending;
        addIncomeTaxData(request)
            .then(() => {
                this.addIncomeTaxDataRequestState = RequestState.Done;
                this.getProfile();
            })
            .catch(() => {
                this.addIncomeTaxDataRequestState = RequestState.Error;
            });
    };

    confirmEmail = (token: string) => {
        this.confirmEmailRequestState = RequestState.Pending;
        return confirmEmail(token)
            .then(() => {
                this.confirmEmailRequestState = RequestState.Done;
            })
            .catch((error) => {
                this.confirmEmailRequestState = RequestState.Error;
            });
    };

    resendEmailConfirmLink = () => {
        this.resendEmailConfirmLinkState = RequestState.Pending;
        return resendEmailConfirmLink()
            .then(() => {
                this.resendEmailConfirmLinkState = RequestState.Done;
                this._rootStore.participants.getProfile();
            })
            .catch((error) => {
                this.resendEmailConfirmLinkState = RequestState.Error;
            });
    }

    changeEmail = (newEmail: string) => {
        return changeEmail(newEmail)
            .then(() => {
                this.getProfile();
            });
    }

    changeEmailWithVerifyCode(newEmail: string, verifyCode: string) : Promise<ApiResult> {
        this.updateEmail = RequestState.Pending;
        return changeEmailWithVerifyCode(newEmail, verifyCode)
          .then((response) => {
              return response;
          })
          .finally(() => {
              this.updateEmail = RequestState.Done;
          });
    }
}
