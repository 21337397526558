import React from "react";
import "./Footer.css";
import {NavLink} from "react-router-dom";
import CurrentClientProfile from "../../client-profile";
import Authorize from "../Authorize/Authorize";
import Switch, {Case, Default} from "react-switch-case";
import {CLIENT_ID} from "../../constants";
import ClientProfile from "../../client-profile";
import Link from "../Link/Link";

const Footer = () => {
    return (
        <div className={"footer"}>
            <div className={"footer__row"}>
                <div className={"footer__link"}>
                    <NavLink to={"/products"}>
                        <Switch condition={CLIENT_ID}>
                            <Case value={"nlmk-catalog-webui"}>
                                Каталог
                            </Case>
                            <Default>
                                { CurrentClientProfile.title || "Каталог подарков"}
                            </Default>
                        </Switch>
                    </NavLink>
                </div>
                <Authorize visible={CurrentClientProfile.sales}>
                    <div className={"footer__link"}>
                        <NavLink to={"/waves/1"}>Акция</NavLink>
                    </div>
                </Authorize>
                <Authorize visible={CurrentClientProfile.userProfile.codes}>
                    <div className={"footer__link"}>
                        <NavLink to={"/profile/codes"}>Активировать код</NavLink>
                    </div>
                </Authorize>
                <div className={"footer__link"}>
                    <NavLink to={"/support"}>Поддержка</NavLink>
                </div>
                {ClientProfile.footerLinks && ClientProfile.footerLinks.map((link, index) => {
                    return (
                        <div className={"footer__link"} key={index}>
                            <Link to={link.url}>{link.title}</Link>
                        </div>
                    );
                })
                }
                <Authorize visible={CurrentClientProfile.sales}>
                    <div className={"footer__link"}>
                        <NavLink to={"/about-products"}>О продукте</NavLink>
                    </div>
                </Authorize>
            </div>
            <div className={"footer__row"}>
                <div className={"footer__logo"}>Powered by Vektor</div>
                <div className={"footer__separate"}/>
                <div className={"footer__copy"}>© 2025 ООО «Вектор»</div>
            </div>
        </div>
    );
};

export default Footer;
