import React, {useEffect} from "react";
import "./ProductsPage.css";
import {Swiper, SwiperSlide} from "swiper/react";
import Slide from "../components/Slides/Slide";
import {observer} from "mobx-react-lite";
import slide1 from "../assets/images/slide-1@2x.png";
import slide2 from "../assets/images/slide-2@2x.png";
import slide3 from "../assets/images/slide-3@2x.png";
import slide4 from "../assets/images/slide-4-2@2x.png";
import slide5 from "../assets/images/ZZH_T30382E11_000_01_4_3_4_3.png";
import {useStores} from "../store";
import Filter from "../components/Filter/Filter";
import ProductList from "../components/ProductList/ProductList";
import {useHistory} from "react-router-dom";
import CurrentClientProfile from "../client-profile";
import Authorize from "../components/Authorize/Authorize";
import {useMediaQuery} from "react-responsive";
import {CLIENT_ID} from "../constants";
import {useQueryState} from "../hooks/useQueryState";

const ProductsPage = () => {
  let {store} = useStores();
  const history = useHistory();
  const [searchValue, setSearchValue] = useQueryState("q");

  const changeCategory = (value: string | null) => {
    store.catalog.setCurrentTag(value);
  };

  const isMobileScreen = useMediaQuery({query: '(max-width: 786px)'})

  useEffect(() => {
    store.catalog.setCurrentSearchValue(searchValue);
  }, [searchValue]);


  useEffect(() => {
    if (store.catalog?.filteredGiftCertificate?.length == 1) {
      history.push(`/products/${store.catalog.giftCertificates[0].id}`);
    }
  }, [store.catalog.giftCertificates]);


  return (
    <div className={"products-page"}>
      <Authorize visible={CurrentClientProfile.banner || store.catalog.banners.length > 0}>
        <div className={"products-page__slider"}>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            onSlideChange={() => {
            }}
            onSwiper={(swiper) => {
            }}
            autoplay
            pagination={{clickable: true}}
          >
            {CLIENT_ID == 'grohe-catalog-webui' && <>
              <SwiperSlide>
                <Slide
                  title1="Двойной бонус за GROHE Blue"
                  title2=""
                  background={slide5}
                  points1={0}
                  points2={0}
                  opacity={0.4}
                  one
                  color="red"
                  buttonText="Подробнее"
                  buttonOnClick={() => history.push("/waves/1/products")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Slide
                  title1={"Водные системы GROHE"}
                  title2={"Кухонные мойки GROHE"}
                  background={slide1}
                  points1={3000}
                  points2={1000}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Slide
                  title1={"Смесители GROHE для мойки"}
                  title2={
                    "Смесители GROHE для мойки коллекций Bau, Start, Euroeco и Costa"
                  }
                  background={slide2}
                  points1={500}
                  points2={300}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Slide
                  title1={"Аксессуары для кухни GROHE"}
                  title2={"Комплект дооснащения GROHE"}
                  background={slide3}
                  points1={150}
                  points2={100}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Slide
                  title1={"Кухонный эксклюзив GROHE"}
                  title2={"Комплект дооснащения GROHE"}
                  background={slide4}
                  points1={500}
                  points2={100}
                  one
                />
              </SwiperSlide>
            </>}
            {store.catalog.banners.filter(x => x.isMobile == isMobileScreen).map((banner) => <SwiperSlide>
              <Slide
                title1=""
                title2=""
                background={banner.path}
                points1={0}
                points2={0}
                opacity={1}
                one
              />
            </SwiperSlide>)}
          </Swiper>
        </div>
      </Authorize>
      <div className={"products-page__filter"}>
        {store.catalog.tags.length > 0 &&
          <Filter maxVisibleCount={isMobileScreen ? 2 : 6} filters={store.catalog.tags}
                  onChange={changeCategory}/>}
      </div>
      <div className={"products-page__list"}>
        <ProductList giftCertificates={store.catalog.filteredGiftCertificate}/>
      </div>
    </div>
  );
};

export default observer(ProductsPage);

