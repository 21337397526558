export interface ApiResult {
    code?: string;
    message?: string;
}

export interface CheckVerifyCodeResult extends ApiResult {
    isVerified?: boolean;
}

export enum OperationType {
    ChangeEmail = 1,
}