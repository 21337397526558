import {httpGet, httpPostJson} from "../helpers/http";
import {ApiResult, CheckVerifyCodeResult, OperationType} from "../types/CheckVerifyCodeResult";


export const addVerifyCode = (operationType: OperationType): Promise<ApiResult> => {
  return httpPostJson("/api/v1/verify-codes", JSON.stringify({type: operationType}));
};


export const checkVerifyCode = (verifyCode: string): Promise<CheckVerifyCodeResult> => {
  return httpPostJson("/api/v1/verify-codes/check", JSON.stringify({code: verifyCode, operationType: 1}));
};
