import moment from "moment";
import {Moment} from "moment/moment";

export interface GiftCertificateFaceValueDto {
  id: number;
  faceValue: FaceValueDto;
  price: number;
}

export interface FaceValueDto {
  id: number;
  value: number;
  unit: FaceValueUnit;
}

export function calcGiftCertificateFaceValuePrice(d: GiftCertificateFaceValueDto, discount: number) {
  if (!d)
    return 0;
  if (isCustomValue(d.faceValue)) {
    return d.price + (d.price * discount / 100);
  } else {
    return d.price;
  }
}

export function isCustomValue(value: FaceValueDto) {
  return value.value == 2147483647;
}

export const formatFaceValue = (faceValue: FaceValueDto) => {
  if (isCustomValue(faceValue)) {
    return '';
  }
  return `${faceValue.value} ${FaceValueUnitsName[faceValue.unit]}`;
}

export function defaultDayFormatDate(value: Moment) {
  if (!value)
    return "";

  return value.format('DD.MM.yyyy г.');
}

export enum FaceValueUnit {
  Ruble = 1,
  Month = 2
}

export const FaceValueUnitsName: Record<FaceValueUnit, string> = {
  [FaceValueUnit.Ruble]: 'руб.',
  [FaceValueUnit.Month]: 'мес.'

};

export interface GiftCertificateFaceValueGroupModel {
  id: number;
  name: string;
  faceValues: GiftCertificateFaceValueDto[];
  vendorFaceValueGroup: VendorFaceValueGroupDto;
  discount: number;
}

export interface VendorFaceValueGroupDto {
  id: number;
  name: string;
}