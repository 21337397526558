import {RootStore} from "./RootStore";
import {RequestState} from "../types/RequestState";
import {makeAutoObservable} from "mobx";
import {CodeModel} from "../types/CodeModel";
import {activateCode, getCodes} from "../services/CodesService";
import {CodeActivateResult} from "../types/CodeActivateResult";
import {ApiResult, CheckVerifyCodeResult, OperationType} from "../types/CheckVerifyCodeResult";
import {addVerifyCode, checkVerifyCode} from "../services/VerifyCodesService";

export class VerifyCodesStore {
  private _rootStore: RootStore;
  verifyCodeState: RequestState = RequestState.Done;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this._rootStore = rootStore;
  }


  add = async (type: OperationType): Promise<ApiResult> => {
    this.verifyCodeState = RequestState.Pending;
    try {
      let response = await addVerifyCode(type);
      this.verifyCodeState = RequestState.Done;
      return response;
    } catch (e) {
      this.verifyCodeState = RequestState.Error;
      throw e;
    }
  };

  check = (verifyCode: string): Promise<CheckVerifyCodeResult> => {
    this.verifyCodeState = RequestState.Pending;
    return checkVerifyCode(verifyCode)
      .then((response) => {
        this.verifyCodeState = RequestState.Done;
        return response;
      }).catch((e) => {
        this.verifyCodeState = RequestState.Error;
        throw e;
      });
  };
}
