import {FaceValueUnit} from "./types/FaceValueModel";
import {CLIENT_ID} from "./constants";
import MercedesLogo from "./assets/images/mbrus.png";
import GroheLogo from "./assets/images/logo.png";
import MainLogo from "./assets/images/vektor-logo.png";
import BayerLogo from "./assets/images/bayer_logo.jpg";
import UpecoLogo from "./assets/images/upeco_logo.png";
import MegapolisLogo from "./assets/images/megapolis.png";
import PmiLogo from "./assets/images/pmi-logo.png";
import JacobsLogo from "./assets/images/jacobs-logo.png";
import HeinekenLogo from "./assets/images/H_MT_ico.png";
import ApicennaLogo from "./assets/images/Logo_apicenna_2.png";
import NlmkLogo from "./assets/images/nlmk.png";
import RaifLogo from "./assets/images/Group3983.png";
import TalatuLogo from "./assets/images/talatu.png";
import BergLogo from "./assets/images/berg-logo.png";
import UuazLogo from "./assets/images/uuaz-logo.png";
import LorenzLogo from "./assets/images/lorenz-logo.png";


import {Property} from "csstype";

export interface ClientProfileHeader {
    height: number;
}

export interface ClientProfileOrder {
    allowDownload: boolean;
}

export interface LogoCss {
    image: string;
    width?: number;
    height?: number;
    backgroundColor?: Property.BackgroundColor;
    padding?: Property.Padding<string>;
}

export interface ClientProfile {
    icon?: string,
    title?: string,
    logo: LogoCss,
    userProfile: UserProfileSettings,
    banner: boolean,
    sales: boolean,
    booklets: boolean,
    about: boolean,
    defaultUrl?: string
    header?: ClientProfileHeader,
    order?: ClientProfileOrder,
    theme?: AppTheme,
    coinName?: string,
    disableTopUp?: boolean,
    faq?: boolean,
    fioAsLogin?: boolean
    footerLinks?: FooterLink[]
    backUrl?: string
}

export interface FooterLink {
    title: string,
    url: string
}

interface AppTheme {
    font: string;
}

interface UserProfileSettings {
    bankCard: boolean
    ndfl: boolean
    dealer: boolean
    codes: boolean
    center?: boolean
    showOnlyEmail?: boolean
    enableChangeEmail?: boolean
}

const clientProfiles: Record<string, ClientProfile> = {
    "grohe-catalog-webui": {
        logo: {
            image: GroheLogo,
            width: 130,
            height: 100
        },
        userProfile: {
            bankCard: true,
            ndfl: true,
            dealer: true,
            codes: false
        },
        banner: true,
        sales: true,
        booklets: true,
        about: true,
        defaultUrl: "/waves/1/products",
        order: {
            allowDownload: false
        }
    },
    "mercedes-catalog-webui": {
        logo: {
            image: MercedesLogo,
            width: 131,
            height: 53
        },
        userProfile: {
            bankCard: true,
            ndfl: true,
            dealer: false,
            codes: true,
            center: false
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "main-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 70,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "15px 20px 18px 5px"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true,
            enableChangeEmail: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true,
        footerLinks: [
            {
                title: 'Пользовательское соглашение',
                url: 'https://storage.yandexcloud.net/vector-public/offers/gc_agreement.docx'
            }
        ],

    },
    "new-year-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 70,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "15px 20px 18px 5px"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "raiffeisen-catalog-webui": {
        title: "Каталог подарков",
        theme: {
            font: "Arial"
        },
        logo: {
            image: RaifLogo,
            width: 100,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "15px 20px 18px 5px"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: false,
            center: true,
            showOnlyEmail: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "nlmk-catalog-webui": {
        title: "Каталог",
        logo: {
            image: NlmkLogo,
            width: 70,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "15px 20px 18px 5px"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: false,
            center: true,
            enableChangeEmail: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        coinName: 'Fe',
        order: {
            allowDownload: true
        },
        disableTopUp: true,
        fioAsLogin: true,
        footerLinks: [
            {
                title: 'Оферта',
                url: 'https://storage.yandexcloud.net/vector-public/offers/nlmk.docx'
            },
            {
                title: 'Пользовательское соглашение',
                url: 'https://storage.yandexcloud.net/vector-public/offers/agreement.docx'
            },
            {
                title: 'Политика конфиденциальности',
                url: 'https://storage.yandexcloud.net/vector-public/offers/policy.docx'
            }

        ],
        backUrl: 'https://nlmk.one/'
    },
    "bayer-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: BayerLogo,
            width: 90,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 5px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "upeco-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: UpecoLogo,
            width: 125,
            height: 26,
            // backgroundColor: "#3884be",
            padding: "10px 0 0 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "gift-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "megapolis-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MegapolisLogo,
            width: 130,
            // height: 30,
            // backgroundColor: "#3884be",
            padding: "15px 0 3px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: true,
            dealer: false,
            codes: true,
            center: false
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "avz-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: true,
            dealer: false,
            codes: true,
            center: false
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "pmi-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: PmiLogo,
            width: 40,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 0 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: false,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        header: {
            height: 80
        },
        order: {
            allowDownload: true
        }
    },
    "pmi-premium-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: PmiLogo,
            width: 40,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 0 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: false,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        header: {
            height: 80
        },
        order: {
            allowDownload: true
        }
    },
    "jacobs-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: JacobsLogo,
            width: 130,
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "otp-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "heineken-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: HeinekenLogo,
            width: 70,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: true,
            ndfl: true,
            dealer: false,
            codes: false,
            center: false
        },
        banner: true,
        sales: false,
        booklets: false,
        about: false
    },
    "apicenna-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: ApicennaLogo,
            width: 130,
            // height: 100,
            // backgroundColor: "#3884be",
            padding: "7px 0 0 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },
    "oph-mt-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: HeinekenLogo,
            width: 70,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 18px 0"
        },
        userProfile: {
            bankCard: true,
            ndfl: true,
            dealer: false,
            codes: false,
            center: false
        },
        banner: true,
        sales: false,
        booklets: false,
        about: false
    },
    "talatu-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: TalatuLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "0 0 47px 0"
        },
        userProfile: {
            bankCard: false,
            ndfl: true,
            dealer: false,
            codes: true,
            center: false
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false
    },
    "beauty-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 70,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "15px 20px 18px 5px"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true,
        footerLinks: [
            {
                title: 'Пользовательское соглашение',
                url: 'https://storage.yandexcloud.net/vector-public/offers/gc_agreement.docx'
            }
        ],
    },

    "granfest-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: MainLogo,
            width: 70,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "15px 20px 18px 5px"
        },
        userProfile: {
            bankCard: true,
            ndfl: true,
            dealer: false,
            codes: false,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true,
        footerLinks: [
            {
                title: 'Пользовательское соглашение',
                url: 'https://storage.yandexcloud.net/vector-public/offers/gc_agreement.docx'
            }
        ],
    },

    "berg-catalog-webui": {
        title: "Потратить баллы",
        logo: {
            image: BergLogo,
            width: 70,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "15px 20px 18px 5px"
        },
        userProfile: {
            bankCard: true,
            ndfl: true,
            dealer: false,
            codes: false,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true,
        footerLinks: [
            {
                title: 'Пользовательское соглашение',
                url: 'https://storage.yandexcloud.net/vector-public/offers/berg_agreement.docx'
            }
        ],
    },

    "uuaz-catalog-webui": {
        title: "Каталог подарков",
        logo: {
            image: UuazLogo,
            width: 135,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "15px 20px 18px 5px"
        },
        userProfile: {
            bankCard: false,
            ndfl: false,
            dealer: false,
            codes: true,
            center: true
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true
    },

    "lorenz-catalog-webui": {
        title: "Потратить баллы",
        logo: {
            image: LorenzLogo,
            width: 130,
            // height: 100
            // backgroundColor: "#3884be",
            padding: "15px 20px 18px 5px"
        },
        userProfile: {
            bankCard: false,
            ndfl: true,
            dealer: false,
            codes: true,
            center: false
        },
        banner: false,
        sales: false,
        booklets: false,
        about: false,
        faq: true,
        footerLinks: [
            {
                title: 'Пользовательское соглашение',
                url: 'https://storage.yandexcloud.net/vector-public/offers/snack_agreement.docx'
            }
        ],
    },
}

const CurrentClientProfile: ClientProfile = clientProfiles[CLIENT_ID];
export default CurrentClientProfile;


export const FaceValueUnitsName: Record<FaceValueUnit, string> = {
    [FaceValueUnit.Ruble]: 'руб.',
    [FaceValueUnit.Month]: 'мес.'
};