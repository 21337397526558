import React from "react";
import "./FaceValueSelector.css";
import {defaultDayFormatDate, formatFaceValue, GiftCertificateFaceValueDto} from "../../types/FaceValueModel";
import classNames from "classnames";
import Tooltip from "../Tooltip/Tooltip";
import {RestGiftCertificatesNumberDto} from "../../types/RestGiftCertificatesNumberDto";
import moment, {defaultFormat} from "moment";
import TextInput from "../TextInput/TextInput";

interface FaceValueSelectorProps {
  faceValues: GiftCertificateFaceValueDto[];
  selectedFaceValue?: GiftCertificateFaceValueDto;
  onSelect: (faceValueModel: GiftCertificateFaceValueDto) => void;
  title: string;
  restGiftCertificatesNumbers: RestGiftCertificatesNumberDto[]
}

const FaceValueSelector = (props: FaceValueSelectorProps) => {

  let freeValue = props.faceValues.find(x => x.faceValue.value === 2147483647);
  return (
    <div className={"face-value-selector"}>
      <div className={"face-value-selector__title"}>{props.title}</div>
      <div className={"face-value-selector__body"}>
        {freeValue && <TextInput id={"amount"} onChange={(value) => {
          freeValue!.price = parseInt(value) || 0;
          props.onSelect(freeValue!);
        }} editable value={freeValue.price.toString()} label={"Введите сумму"}/>}
        {!freeValue && props.faceValues.sort((a, b) => a.price > b.price && 1 || -1).map((faceValue) => {
          let fg = props.restGiftCertificatesNumbers.filter(x => x.faceValueId == faceValue.faceValue.id)[0];
          let tooltip = "";
          if (fg?.count > 0) {
            tooltip = `Доступное кол-во: ${fg.count} шт.\nСрок действия сертификата: до ${defaultDayFormatDate(moment.utc(fg.endDate.seconds * 1000))}`;
          } else {
            tooltip = `Сертификата нет в наличии (0 шт.). При заказе он поступит Вам в течение 3-5 рабочих дней, не считая дня заказа`;
          }

          return (<Tooltip key={faceValue.faceValue.id} content={tooltip} align={"left"}>
            <div
              key={faceValue.faceValue.id}
              className={classNames({
                "face-value-selector__face-value": true,
                "face-value-selector__face-value--selected":
                  faceValue.faceValue.id === props.selectedFaceValue?.faceValue.id,
              })}
              onClick={() => {
                props.onSelect(faceValue)
              }}
            >
              {formatFaceValue(faceValue.faceValue)}
            </div>
          </Tooltip>)
        })}
      </div>
    </div>
  );
};

export default FaceValueSelector;
