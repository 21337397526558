import { RootStore } from "./RootStore";
import {makeAutoObservable, remove} from "mobx";
import { CartItemModel } from "../types/CartItemModel";
import ID from "../helpers/id";
import {FaceValueDto, formatFaceValue} from "../types/FaceValueModel";
import {CUSTOM_FACE_VALUE_ID} from "../constants";

export class CartStore {
  private _rootStore: RootStore;
  public items: CartItemModel[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this._rootStore = rootStore;
    this.items = JSON.parse(localStorage.getItem("cart") || "[]");
  }

  clear(){
    this.items = []
    localStorage.setItem("cart", "[]");
  }

  get total(): number {
    let sum = 0;

    this.items.forEach((item) => {
      sum += (item.price + (CUSTOM_FACE_VALUE_ID == item.faceValueId ? (item.price * item.discount/100): 0)) * item.quantity;
    });

    return sum;
  }

  addCertificate(
    productId: number,
    name: string,
    img: string,
    faceValue: FaceValueDto,
    price: number,
    vendorId:number,
    vendorFaceValueGroupId: number,
    discount: number
  ) {
    let item = this.items.find(
      (i) => i.productId === productId && i.faceValueId === faceValue.id
    );

    if (item) {
      item.quantity++;
    } else {
      this.items.push({
        id: ID(),
        productId: productId,
        quantity: 1,
        cartImagePath: img,
        name: name,
        price: price,
        faceValue: formatFaceValue(faceValue),
        faceValueId: faceValue.id,
        vendorId:vendorId,
        vendorFaceValueGroupId: vendorFaceValueGroupId,
        discount: discount
      });
    }
    this.items = [...this.items];
    localStorage.setItem("cart", JSON.stringify(this.items));
  }

  increase(id: string) {
    let findItem = this.items.find((x) => x.id === id);
    if (findItem) findItem.quantity++;
    this.items = [...this.items];
    localStorage.setItem("cart", JSON.stringify(this.items));
  }

  decrease(id: string) {
    let findItem = this.items.find((x) => x.id === id);
    if (findItem && findItem.quantity > 1) {
      findItem.quantity--;
      this.items = [...this.items];
      localStorage.setItem("cart", JSON.stringify(this.items));
    } else {
      this.remove(id);
    }
  }

  remove(id: string) {
    this.items = [...this.items.filter((x) => x.id !== id)];
    localStorage.setItem("cart", JSON.stringify(this.items));
  }
}
