import React, {ChangeEvent, useEffect, useState} from "react";
import "./TextInput.css";
import classNames from "classnames";
import MaskedInput from "react-text-mask";

export enum TextInputSize {
    Big,
    Middle,
    Small,
}

interface TextInputProps {
    label?: string;
    id: string;
    onChange: (value: string) => void;
    value?: string;
    editable?: boolean;
    required?: boolean;
    isError?: boolean;
    mask?: Array<string | RegExp>;
    maxLength?: number;
    size?: TextInputSize;
}

const TextInput = (props: TextInputProps) => {
    const [focus, setFocus] = useState(false);
    const [changed, setChanged] = useState(false);
    const [focused, setFocused] = useState(false);

    const onChange = (el: ChangeEvent<HTMLInputElement>) => {
        setChanged(true)
        if (props.editable) {
            props.onChange(el.target.value);
        }
    };

    const onFocus = () => {
        setFocus(true);
    };

    const onBlur = () => {
        setFocus(false);
        setFocused(true)
    };

    return (
        <div className={classNames({
            "text-input": true,
            "text-input--middle": props.size == TextInputSize.Middle,
            "text-input--small": props.size == TextInputSize.Small,
            "text-input--error": props.isError || ((focused || changed) && props.required && (!props.value || (props.value && props.value.length === 0)))
        })}>
            {props.label && <label
                htmlFor={props.id}
                className={classNames({
                    "text-input__label": true,
                    "text-input__label--cleared":
                        !props.value || (props.value && props.value.length === 0),
                    "text-input__label--filled": props.value && props.value.length > 0,
                    "text-input__label--focus": focus
                })}
            >
                {props.label}
            </label>}

            {props.mask ? (
                <MaskedInput
                    disabled={!props.editable}
                    id={props.id}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={props.value}
                    type={"text"}
                    mask={props.mask}
                    guide={false}
                    maxLength={props.maxLength}
                />
            ) : (

                <input
                    maxLength={props.maxLength}
                    disabled={!props.editable}
                    id={props.id}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={props.value}
                    type={"text"}
                />
            )}
        </div>
    );
};

export default TextInput;
