import {RootStore} from "./RootStore";
import {makeAutoObservable} from "mobx";
import {AddOrderRequestModel} from "../types/AddOrderRequestModel";
import {addOrder, resend, get, downloadItem} from "../services/OrdersService";
import {RequestState} from "../types/RequestState";
import {OrderEventModel} from "../types/OrderEventModel";

export class OrderStore {
  private _rootStore: RootStore;
  addOrderRequestState = RequestState.Init;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this._rootStore = rootStore;
  }

  addOrder(successCallback: Function) {
    this.addOrderRequestState = RequestState.Pending;
    let request: AddOrderRequestModel[] = [];
    this._rootStore.cart.items.forEach((item) => {
      request.push({
        vendorId: item.vendorId,
        faceValueId: item.faceValueId,
        quantity: item.quantity,
        vendorFaceValueGroupId: item.vendorFaceValueGroupId,
        price: item.price
      });
    });
    addOrder(request)
      .then(() => {
        const sum = this._rootStore.cart.items.reduce((a, b) => a + b.quantity*b.price, 0)
        this._rootStore.participants.decreaseBalance(sum);
        successCallback();
        this.addOrderRequestState = RequestState.Done;
        setTimeout(()=>{this._rootStore.participants.getBalance()},5000)
      })
      .catch((e) => {
        this.addOrderRequestState = RequestState.Error;
        console.log(e);
      });
  }

  resend(id: number) {
    return resend(id);
  }

  downloadItem(id: number, orderItemId: number) {
    return downloadItem(id, orderItemId);
  }

  get(id: number) : Promise<OrderEventModel> {
    return get(id);
  }

}
